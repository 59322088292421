<template>
  <div>
    <div v-if="currentIndex > -1" class="step">
      <van-sticky>
        <van-steps :active="currentIndex" active-icon="success" active-color="#57ccc2">
          <template v-for="(item, index) in pageSteps">
            <van-step :key="index">{{ index + 1 }}.{{ item.title }}</van-step>
          </template>
        </van-steps>
      </van-sticky>
    </div>
    <FloatBtn v-if="pageData.storageFlag" @click="saveTemp" text="暂" />
    <router-view></router-view>
  </div>
</template>
<script>
import FloatBtn from "@/components/FloatBtn";
import layoutData from "@/views/product/layout/claimData";
import scriptLib from "@/views/product/layout/claimScript";
import { request } from "@/api/service";
export default {
  name: "Flow",
  components: { FloatBtn },
  provide() {
    return {
      pageContext: this,
      pageForm: this.pageForm,
      pageLayout: this.pageLayout,
      pageLayoutMap: this.pageLayoutMap,
      PageScript: this.PageScript,
      pagePre: this.pagePre,
      pageNext: this.pageNext
    };
  },
  data() {
    return {
      pageData: {
        storageFlag: true
      },
      productCode: null,
      productData: null,
      pageForm: {},
      pageLayout: [],
      pageLayoutMap: {},
      PageScript: scriptLib.call(this),
      pageSteps: [
        {
          name: "填写信息",
          code: "BaseClaim",
          path: "",
          pathName: "BaseClaim",
          title: "填写信息"
        },
        {
          name: "上传影像",
          code: "FileClaim",
          path: "",
          pathName: "FileClaim",
          title: "上传影像"
        },
        {
          name: "完成",
          code: "SuccessClaim",
          path: "",
          pathName: "SuccessClaim",
          title: "完成"
        }
      ]
    };
  },
  computed: {
    currentIndex() {
      if (!(this.pageSteps && this.pageSteps.length > 0)) {
        return -1;
      }
      // eslint-disable-next-line guard-for-in
      for (const stepIndex in this.pageSteps) {
        const step = this.pageSteps[stepIndex];
        if (step.pathName === this.$route.name) {
          return stepIndex;
        }
      }
      return 0;
    },
    scriptObj() {
      const pageScriptObj = new this.PageScript();
      pageScriptObj.pageForm = this.pageForm;
      pageScriptObj.pageLayout = this.pageLayout;
      pageScriptObj.pageLayoutMap = this.pageLayoutMap;
      pageScriptObj.pageContext = this;
      return pageScriptObj;
    }
  },
  created() {
    const { productCode, id } = this.$route.query;
    this.productCode = productCode;
    this.initLayout();
    // 初始化数据
    this.scriptObj.serviceToPage(id);
    this.scriptObj.created && this.scriptObj.created();
  },
  methods: {
    // 初始化表单和布局
    async initLayout() {
      this.productData = await layoutData.call(this);
    },
    saveTemp() {
      // 转换提交数据
      const submitForm = this.scriptObj.pageToService();
      request({
        url: "/afis-carservice/claim/commonSaveTemp",
        method: "POST",
        data: submitForm
      })
        .then(res => {
          this.$toast("暂存成功");
          this.pageForm.id = res;
        })
        .catch(error => {});
    },
    pagePre(query) {
      if (!this.pageSteps) {
        return false;
      }
      const nextSteps = this.pageSteps.filter((item, index) => {
        return index < this.currentIndex;
      });
      const flowItem = nextSteps[nextSteps.length - 1];
      if (!flowItem) {
        return false;
      }
      this.$router.push({
        name: flowItem.pathName,
        query: query
      });
    },
    pageNext(query, replaceFlag) {
      if (!this.pageSteps) {
        return false;
      }
      const nextSteps = this.pageSteps.filter((item, index) => {
        return index > this.currentIndex;
      });
      const flowItem = nextSteps[0];
      if (!flowItem) {
        return false;
      }
      if (replaceFlag) {
        this.$router.replace({
          name: flowItem.pathName,
          query: query
        });
        return false;
      }
      this.$router.push({
        name: flowItem.pathName,
        query: query
      });
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/style/var.scss";
.step {
}
</style>
